import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Map } from 'immutable';
import Spinner from './Spinner';
import TooltipError from './TooltipError';
import './ImportBox.scss';

const ImportBox = ({ upload, label, downloadUrl, status, hideExport }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [file, setFile] = useState('');
  const filesInput = useRef(null);

  const toggleVisibility = () => {
    setIsVisible((visible) => !visible);
  };

  const onFileSelect = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = (e) => {
    e.preventDefault();
    filesInput.value = '';
    setFile('');

    const formData = new window.FormData();
    formData.append('file', file);

    upload(formData);
  };

  return (
    <div className="import-container">
      <div onClick={toggleVisibility} className="import-header">
        <div className="">
          <a>
            <i className={`fa ${!isVisible ? 'fa-plus' : 'fa-minus'}`} />{' '}
            {label}
          </a>
        </div>
      </div>
      <form
        onSubmit={uploadFile}
        className={`import-form ${isVisible ? '' : 'hidden'}`}
      >
        <input
          name="file"
          type="file"
          ref={filesInput}
          onChange={onFileSelect}
        />
        <br />
        <button className="save-button" type="submit">
          {status.get('uploadError') ? (
            <TooltipError
              description={status.getIn(['uploadError', 'error'])}
            />
          ) : null}
          {status.get('uploading') ? <Spinner /> : null}
          <span className="label">
            {status.get('uploading') ? 'Uploading...' : 'Upload'}
          </span>
        </button>

        {status.get('uploadError') ? (
          <div style={{ marginTop: '5px', color: '#D8000C' }}>
            {status.getIn(['uploadError', 'error'])}
          </div>
        ) : null}

        {!hideExport && (
          <a href={downloadUrl} className="template-download-link">
            <i className="fa fa-download" /> <span>Download template</span>
          </a>
        )}
      </form>
    </div>
  );
};

ImportBox.defaultProps = {
  hideExport: null,
  downloadUrl: null
};

ImportBox.propTypes = {
  upload: PropTypes.func.isRequired,
  status: PropTypes.instanceOf(Map).isRequired,
  label: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string,
  hideExport: PropTypes.bool
};

export default ImportBox;
